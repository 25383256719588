.flecha-animada-scroll {
  position: sticky;
  align-self: flex-end;
  right: 0;
  bottom: 50px;
  width: 50px;
  height: 50px;
  margin: 0;
  color: var(--colores-principales-cima-amarillo);
  opacity: 0;
}
