#enviarCorreoClienteAgencia {
  width: 300px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px #6d6d6d;
}

#enviarCorreoClienteAgencia label {
  font-weight: 700;
  color: rgb(133, 133, 133);
  margin-bottom: 30px;
}

#enviarCorreoClienteAgencia input {
  border: none;
  border-bottom: #7d7d7d solid 1px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #3d3d3d;
  width: 95%;
  text-align: center;
}

#enviarCorreoClienteAgencia input:focus {
  outline: none;
}

#enviarCorreoClienteAgencia button {
  background-color: var(--colores-principales-cima-verde);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 200px;
  min-height: 40px;
  border-radius: 15px;
  transition: all 0.1s;
  cursor: pointer;
}

#enviarCorreoClienteAgencia button:hover {
  background-color: var(--colores-principales-cima-amarillo);
}

#enviarCorreoClienteAgencia button:active {
  background-color: var(--colores-principales-cima-verde);
}
