#listaDeResultados {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
}

.opcionDeCombinacion{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column;
    background-color: rgba(255, 255, 255, 70%);
    border: rgb(132,153,104) solid 1px;
    margin: 20px 5px;
    box-shadow: 2px 3px 8px black;
    border-radius: 30px;
}

.opcionDeCombinacion p {
    margin: 2px 0;
    font-weight: 700;
    color: hsl(0, 0%, 40%);
    font-size: 1.2rem;
}


.mensajes{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 750px){
    #listaDeResultadosFila {
        flex-wrap: wrap;
        justify-content: center;
    }
}