html {
  background-image: url("./assets/imagenes/background.jpg");
  background-attachment: fixed;
  background-size: cover;
  -webkit-tap-highlight-color: transparent;
  /*Supported by WebKit/Safari, Blink/Chrome, and some versions of Internet Explorer and Microsoft Edge. */
  touch-action: manipulation;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  height: 100vh;
  width: 100vw;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --colores-principales-cima-cafe: rgb(102, 55, 0);
  --colores-principales-cima-amarillo: rgb(136, 109, 0);
  --colores-principales-cima-verde: rgb(0, 71, 18);
  --colores-principales-cima-marron: rgb(53, 14, 0);
  --colores-secundarios-precio-oferta: rgb(190, 0, 0);
}
