.cabanaDeOpcion {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 0 5px;
}

.cabanasDeOpcion {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.opcionesResultadosCombinados {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding-bottom: 10px;
}
.opcionesResultadosCombinados:hover .portadasResultados {
  clip-path: circle(70%);
}

.opcionesResultadosCombinados p .tachado {
  text-decoration: line-through;
}

.opcionesResultadosCombinados p .precioConDescuento {
  color: var(--colores-secundarios-precio-oferta);
  font-size: 18px;
}

@media screen and (max-width: 560px) {
  .cabanasDeOpcion {
    flex-wrap: wrap;
    width: 100%;
  }
  .cabanaDeOpcion {
    max-width: 45%;
  }
}
