div#comparador {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    box-sizing: border-box;
}

#calendarioYCantidadDeHuespedes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 5px;
}

#calendarioYCantidadDeHuespedes h1{
    margin: 2px 0;
    font-size: 1.7rem;
}

#cantidadDeHuespedesYBuscar {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.botonTipoDeBusqueda{
    background-color: var(--colores-principales-cima-verde);
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 300px;
    height: 30px;
    border-radius: 15px;
    transition: all 0.08s;
    margin-top: 5px;
    cursor: pointer;
}
    
.botonTipoDeBusqueda:hover {
    background-color: var(--colores-principales-cima-amarillo);
}