.modalMiCuenta {
  width: 300px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px #6d6d6d;
}

.modalLoginRegistro {
  min-width: 300px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px #6d6d6d;
}

.modalLoginRegistroTabs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.modalLoginTabActivo {
  color: var(--colores-principales-cima-verde);
  border-bottom: rgb(0 71 18 / 50%) solid 3px;
}

.modalLoginTabInactivo {
  color: rgb(133, 133, 133);
  cursor: pointer;
  border-bottom: rgb(133 133 133 / 50%) solid 0px;
}

.modalLoginTabInactivo:hover {
  color: rgb(77, 77, 77);
  cursor: pointer;
  border-bottom: rgb(0 71 18 / 50%) solid 0px;
}

.modalLoginRegistroTabs p {
  margin: 0;
  font-weight: 700;
  padding: 0px 10px 10px 10px;
  transition: all 0.2s;
}

#formRegistroModal,
#formLoginModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  overflow-y: auto;
}

#formRegistroModal label,
#formRegistroModal input,
#formLoginModal label,
#formLoginModal input {
  width: 95%;
}

.previewImagenPerfilLoginYRegistrarme {
  max-width: 150px;
  clip-path: circle(42%);
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.imagenPerfilModalMiCuenta {
  max-width: 150px;
  clip-path: circle(42%);
}

.modalLoginRegistro .cambiarImagenDePerfil{
  font-size: 14px;
  font-weight: 600;
  color: var(--colores-principales-cima-verde);
  cursor: pointer;
}

.modalLoginRegistro .cambiarImagenDePerfil:hover{
  text-decoration: underline;
}

.modalLoginRegistro label {
  font-size: 12px;
  font-weight: 600;
  color: rgb(133, 133, 133);
}

.modalLoginRegistro input {
  border: none;
  border-bottom: #7d7d7d solid 1px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #3d3d3d;
}

.modalLoginRegistro input:focus {
  outline: none;
}

.divBotonesMiCuenta {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
}

.botonesMiCuenta {
  background-color: var(--colores-principales-cima-verde);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 200px;
  min-height: 40px;
  margin: 5px 0px;
  border-radius: 15px;
  transition: all 0.1s;
  cursor: pointer;
}

.botonMiCuentaCerrarSesion {
  background-color: var(--colores-principales-cima-marron);
}

.botonEnviarLoginYRegistro {
  background-color: var(--colores-principales-cima-verde);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 100px;
  min-height: 40px;
  border-radius: 15px;
  transition: all 0.1s;
  cursor: pointer;
}

.botonCerrarLoginYRegistro {
  align-self: flex-end;
  color: rgb(87, 87, 87);
  font-size: 20px;
  cursor: pointer;
}

.botonEnviarLoginYRegistro:hover,
.botonesMiCuenta:hover {
  background-color: var(--colores-principales-cima-amarillo);
}

.botonEnviarLoginYRegistro:active,
.botonesMiCuenta:active {
  background-color: var(--colores-principales-cima-verde);
}

.saludoModalMiCuenta {
  font-size: 28px;
  font-weight: 600;
  color: dimgrey;
  margin: 0;
  text-align: center;
}

.noRecuerdoPassword {
  color: var(--colores-principales-cima-verde);
  cursor: pointer;
}

.noRecuerdoPassword:hover {
  text-decoration: underline;
}
