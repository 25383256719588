#presentacionDeCalendario {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#presentacionDeCalendario h1 {
  margin: 0;
}

#contenedorCalendario {
  display: flex;
  justify-content: center;
}

.botonesCalendario {
  width: 30px;
  transition: all 0.08s;
  transform: rotate(-90deg);
}

.botonesCalendario:active {
  width: 25px;
}

#contenedorCalendario button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}

#fechaDeEntradaYSalida {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 6px;
}

#fechaDeEntradaYSalida table {
  width: 88%;
  padding: 0;
  border-collapse: collapse;
  background-color: white;
  border-spacing: 0;
  border-radius: 10px;
  margin: 0 3px;
  padding: 5px;
  box-shadow: 2px 3px 8px black;
}

#fechaDeEntradaYSalida .fechasBonitas h1 {
  color: var(--colores-principales-cima-amarillo);
  margin: 0;
  font-size: 40px;
}

#fechaDeEntradaYSalida .fechasBonitas p {
  color: var(--colores-principales-cima-verde);
  margin: 0 0 5px 0;
  font-size: 18px;
  font-weight: 700;
}

#fechaDeEntradaYSalida td,
#fechaDeEntradaYSalida th {
  height: 23px;
  width: 50%;
}

#presentacionDeCalendario .leyendas {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
  width: 86%;
  padding: 0;
  border-collapse: collapse;
  background-color: white;
  border-spacing: 0;
  border-radius: 10px;
  margin: 0 3px;
  padding: 5px;
  box-shadow: 2px 3px 8px black;
  margin-top: 3px;
}

#presentacionDeCalendario .leyendas div {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

#presentacionDeCalendario .leyendas div p {
  margin: 0;
  font-size: 12px;
}

#presentacionDeCalendario .leyendas .noDisponible,
#presentacionDeCalendario .leyendas .restringida,
#presentacionDeCalendario .leyendas .seleccionada {
  border-radius: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#presentacionDeCalendario .leyendas .seleccionada {
  background-color: rgba(0, 71, 18, 0.7);
  font-weight: bold;
}

#presentacionDeCalendario .leyendas .restringida {
  background-color: hsl(0deg 93% 71% / 52%);
  color: #910000;
  text-decoration: line-through;
}

#presentacionDeCalendario .leyendas .noDisponible {
  background-color: hsl(0, 0%, 90%);
  color: gray;
  text-decoration: line-through;
}

@media screen and (max-width: 560px) {
  #presentacionDeCalendario h1 {
    margin-top: 50px;
  }

  #contenedorCalendario {
    flex-flow: column;
    align-items: center;
  }

  .botonesCalendario {
    transform: rotate(0deg);
  }

  #contenedorCalendario button {
    margin: 5px 0;
  }
  #fechaDeEntradaYSalida table {
    font-size: 0.8rem;
  }
}
