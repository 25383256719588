#visualizador-cupon {
  align-self: flex-start;
  position: sticky;
  top: 5vh;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 3px #cecece;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  height: fit-content;
  max-width: 720px;
  width: 95vw;
  max-height: 90vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}

#visualizador-cupon table {
  width: 100%;
}

#visualizador-cupon .td-codigo-visualizador-cupon {
  width: 50%;
  text-align: center;
  background-color: white;
}

#visualizador-cupon .td-descuento-visualizador-cupon {
  width: 50%;
  text-align: center;
  background-color: white;
}

#visualizador-cupon .td-fechas-visualizador-cupon {
  width: 100%;
  text-align: center;
  background-color: white;
}

#visualizador-cupon .div-botones-visualizador-cupon {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

#visualizador-cupon .div-botones-visualizador-cupon button {
  background-color: var(--colores-principales-cima-verde);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 110px;
  min-height: 40px;
  margin: 5px 0px;
  border-radius: 15px;
  transition: all 0.1s;
  cursor: pointer;
}

#visualizador-cupon .div-botones-visualizador-cupon button:hover {
  background-color: var(--colores-principales-cima-amarillo);
}
#visualizador-cupon .div-botones-visualizador-cupon button:active {
  background-color: var(--colores-principales-cima-verde);
}
