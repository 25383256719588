.mercado-pago {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.mercado-pago h3 {
  text-align: center;
  margin: 5px;
}

.mercado-pago h4 {
  margin: 0px;
}

.mercado-pago .pago-procesado-por-mercado-pago {
  width: 100%;
  margin: 8px auto;
  text-align: center;
}

.mercado-pago .pago-procesado-por-mercado-pago p {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
}

.icono-cargando-pago {
  width: 100px;
  height: 100px;
  animation: rotation 2s infinite linear;
}

.mercado-pago .div-opciones-pago {
  width: 100%;
  text-align: center;
}

.mercado-pago .div-opciones-pago div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.mercado-pago .opcion-pago {
  box-shadow: 0px 0px 10px 3px #dfdfdf;
  background-color: white;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-width: 152px;
  min-height: 75px;
  margin: 5px;
  transition: all 0.2s;
  cursor: pointer;
}

.mercado-pago .opcion-de-pago-seleccionada {
  transition: all 0.2s;
  background-color: rgb(255, 248, 232);
  border: rgb(192, 192, 192) 3px solid;
}

.mercado-pago .opcion-pago div {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mercado-pago .opcion-pago div img {
  max-width: 160px;
}

.mercado-pago .opcion-pago label {
  font-size: 12px;
  font-weight: 500;
}

.mercado-pago .div-afiliados-thumbnails p {
  font-size: 10px;
}

.mercado-pago .div-afiliados-thumbnails div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row;
}

.mercado-pago .div-afiliados-thumbnails div img {
  height: 25px;
}

.mercado-pago #paymentForm {
  width: 100%;
}

.mercado-pago #paymentForm label {
  font-size: 12px;
  font-weight: 700;
  color: #5a5a5a;
}

.mercado-pago #paymentForm input {
  width: 100%;
  border: none;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: gray;
  border-bottom: rgba(128, 128, 128, 0) solid 2px;
}

.mercado-pago #paymentForm .div-mes-anio-tarjeta .div-datos-formulario-tarjeta {
  width: 181.5px;
  box-sizing: content-box;
}

.mercado-pago #paymentForm .div-mes-anio-tarjeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  /* border: 3px #d8d8d8 solid; */
}

.mercado-pago #paymentForm input:focus {
  outline: none;
  border-bottom: gray solid 2px;
}

.mercado-pago #paymentForm select {
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: 420;
  color: gray;
}

#paymentForm select:focus {
  outline: none;
}

.mercado-pago #paymentForm .div-formulario-tarjeta {
  display: flex;
  flex-flow: column;
  align-items: center;
}

#paymentForm .div-datos-formulario-tarjeta {
  width: 400px;
  display: flex;
  flex-flow: column;
  border: 3px #d8d8d8 solid;
  padding: 5px;
  margin: 2.5px 0;
  border-radius: 5px;
  box-sizing: border-box;
}

#paymentForm .boton-pagar-tarjeta {
  display: flex;
  max-width: 500px;
  width: 100%;
  font-size: 18px;
  justify-content: center;
  background-color: var(--colores-principales-cima-verde);
  color: white;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s;
  border: none;
  cursor: pointer;
  min-width: 130px;
}

.mercado-pago .div-orden-pago {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.mercado-pago .div-orden-pago span {
  font-weight: 700;
}

.mercado-pago .div-orden-pago button {
  display: flex;
  max-width: 500px;
  width: 100%;
  font-size: 18px;
  justify-content: center;
  background-color: var(--colores-principales-cima-verde);
  color: white;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s;
  border: none;
  cursor: pointer;
  min-width: 130px;
  min-height: 40px;
  margin-bottom: 15px;
}

@media screen and (max-width: 440px) {
  .mercado-pago {
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .mercado-pago #paymentForm .div-datos-formulario-tarjeta,
  .mercado-pago #paymentForm .div-mes-anio-tarjeta {
    /* min-width: 95%; */
  }
}
