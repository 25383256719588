.datosDeReservacion {
  width: 100%;
  height: calc(100vh - 60px);
  padding-top: 60px;
  display: grid;
  grid-template-areas:
    "r r r"
    "ce ce ce"
    "i f b"
    "i h c"
    "a a a";
}

.regresarAMisReservaciones {
  width: fit-content;
  grid-area: r;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  margin-left: 16px;
  cursor: pointer;
  border-bottom: rgba(0, 71, 18, 0) solid 2px;
  transition: all 0.5s;
}

.regresarAMisReservaciones:hover {
  color: var(--colores-principales-cima-verde);
  border-bottom: var(--colores-principales-cima-verde) solid 2px;
}

.regresarAMisReservaciones p {
  margin: 0 12px;
}

.codigoYEstatusDatosReservacion {
  grid-area: ce;
  width: 100%;
  text-align: center;
  margin-top: 0;
}

.imagenDatosReservacion {
  grid-area: i;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.imagenDatosReservacion div {
  display: flex;
}

.imagenDatosReservacion img {
  border-radius: 6px;
  width: 100%;
  margin: 3px;
  min-width: 0;
  flex: 1 1;
}

.fechasDatosReservacion {
  grid-area: f;
}

.huespedesDatosReservacion {
  grid-area: h;
}

.clienteDatosReservacion {
  grid-area: c;
}

.balanceDatosReservacion {
  grid-area: b;
}

.archivosDatosReservacion {
  grid-area: a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.areasDatosReservacion {
  padding: 12px;
  margin: 12px;
  background: #ffffff85;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 0px 7px 0px #a1a1a1;
}

.areasDatosReservacion h2 {
  width: 100%;
  text-align: center;
  margin-top: 0;
}

.clienteDatosReservacionDivBoton {
  display: flex;
  justify-content: center;
  width: 100%;
}

.clienteDatosReservacion input {
  border: none;
  border-bottom: var(--colores-principales-cima-verde) solid 1px;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  color: dimgrey;
  transition: all 0.4s;
}

.clienteDatosReservacion input:focus {
  outline: none;
  color: var(--colores-principales-cima-verde);
}

.botonesDetallesReservacion {
  background-color: var(--colores-principales-cima-verde);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  padding: 12px;
  margin: 3px;
  height: 40px;
  border-radius: 15px;
  transition: all 0.1s;
  cursor: pointer;
}

.botonesDetallesReservacion:hover {
  background-color: var(--colores-principales-cima-amarillo);
}

.botonesDetallesReservacion:active {
  background-color: var(--colores-principales-cima-verde);
}

@media screen and (max-width: 1240px) {
  .codigoYEstatusDatosReservacion {
    font-size: 24px;
  }

  .areasDatosReservacion h2 {
    font-size: 18px;
  }

  .areasDatosReservacion h3 {
    font-size: 16px;
  }

  .botonesDetallesReservacion {
    font-size: 14px;
  }
}

@media screen and (max-width: 1020px) {
  .codigoYEstatusDatosReservacion {
    font-size: 20px;
  }

  .areasDatosReservacion h2 {
    font-size: 16px;
  }

  .areasDatosReservacion h3 {
    font-size: 14px;
  }

  .botonesDetallesReservacion {
    font-size: 12px;
  }
}

@media screen and (max-width: 820px) {
  .datosDeReservacion {
    grid-template-areas:
      "r r r"
      "ce ce ce"
      "i i b"
      "i i h"
      "f c c"
      "a a a";
  }
}

@media screen and (max-width: 620px) {
  .datosDeReservacion {
    grid-template-areas:
      "r r r"
      "ce ce ce"
      "i i b"
      "i i h"
      "f f f"
      "c c c"
      "a a a";
  }
}

@media screen and (max-width: 400px) {
  .datosDeReservacion {
    grid-template-areas:
      "r r r"
      "ce ce ce"
      "i i i"
      "b b h"
      "f f f"
      "c c c"
      "a a a";
  }

  .regresarAMisReservaciones p {
    font-size: 14px;
  }

  .codigoYEstatusDatosReservacion {
    font-size: 14px;
    margin-top: 15px;
  }

  .areasDatosReservacion h2 {
    font-size: 12px;
  }

  .areasDatosReservacion h3 {
    font-size: 12px;
  }

  .botonesDetallesReservacion {
    font-size: 10px;
  }
}
