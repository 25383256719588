.meses {
  font-size: 1rem;
}

.meses table {
  background-color: white;
  border-spacing: 0;
  border-radius: 10px;
  margin: 3px;
  padding: 5px;
  box-shadow: 2px 3px 8px black;
}

#diasDeLaSemana td {
  background-color: white;
  font-size: 0.7rem;
  font-weight: 700;
  color: gray;
  height: 0;
}

td {
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 100%;
}
