.galeria,
.galeria div,
.galeria button,
.imagen-en-vista-galeria {
  max-height: 250px;
}

.galeria {
  width: 100%;
  background-size: cover;
  border-radius: 8px;
}

.galeria div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(3px) brightness(0.5);
  border-radius: 8px;
}

.galeria button {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.galeria button svg {
  height: 50px;
  width: 50px;
  color: white;
  transition: all 0.2s;
}

.galeria button svg:hover {
  height: 60px;
  width: 60px;
}

.galeria button svg:active {
  height: 50px;
  width: 50px;
}

/* .imagen-en-vista-galeria {
  max-width: calc(100% - 120px);
} */

/* @media screen and (max-width: 1200px) {
  .galeria,
  .galeria div,
  .galeria button,
  .imagen-en-vista-galeria {
    max-height: 200px;
  }

  .galeria button {
    height: 30px;
    width: 30px;
  }

  .galeria button svg {
    height: 30px;
    width: 30px;
  }

  .galeria button svg:hover {
    height: 30px;
    width: 30px;
  }

  .galeria button svg:active {
    height: 30px;
    width: 30px;
  }
} */

@media screen and (max-width: 600px) {
  .galeria,
  .galeria div,
  .galeria button,
  .imagen-en-vista-galeria {
    max-height: 190px;
  }

  
  .galeria button {
    height: 30px;
    width: 30px;
  }


  .galeria button svg {
    height: 30px;
    width: 30px;
  }

  .galeria button svg:hover {
    height: 30px;
    width: 30px;
  }

  .galeria button svg:active {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 400px) {
  .galeria,
  .galeria div,
  .galeria button,
  .imagen-en-vista-galeria {
    max-height: 160px;
  }

  
  .galeria button {
    height: 30px;
    width: 30px;
  }


  .galeria button svg {
    height: 30px;
    width: 30px;
  }

  .galeria button svg:hover {
    height: 30px;
    width: 30px;
  }

  .galeria button svg:active {
    height: 30px;
    width: 30px;
  }
}
