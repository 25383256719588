.fechasPasadas,
.fechasSeleccionadas,
.fechasNoSeleccionadas,
.fechasReservadas,
.fechaNoDisponibleParaSalida {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transition: all 0.8s;
}

.fechasPasadas {
  color: rgb(206, 206, 206);
  user-select: none;
  cursor: auto;
}

.fechasReservadas {
  background-color: hsl(0, 0%, 90%);
  text-decoration: line-through;
  color: gray;
  cursor: not-allowed;
  border-radius: 8px;
}

.fechasSeleccionadas {
  background-color: rgba(0, 71, 18, 0.7);
  font-weight: bold;
  border-radius: 0;
}

.fechasNoSeleccionadas:hover {
  background-color: rgba(0, 71, 18, 0.3);
  border-radius: 100%;
  cursor: pointer;
}

#fechaDeEntrada {
  background-color: rgba(0, 71, 18, 0.7);
  font-weight: bold;
  border-bottom-left-radius: 100%;
  border-top-left-radius: 100%;
}

#fechaDeSalida {
  background-color: rgba(0, 71, 18, 0.7);
  font-weight: bold;
  border-bottom-right-radius: 100%;
  border-top-right-radius: 100%;
}

.fechaNoDisponibleParaSalida {
  background-color: hsl(0deg 93% 71% / 52%);
  text-decoration: line-through;
  color: #910000;
  cursor: not-allowed;
  border-radius: 8px;
}
