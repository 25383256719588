.panelUsuario {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.contenidoPanelUsuario {
  opacity: 0;
}

.panelUsuario .modalEliminarCuenta {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px #6d6d6d;
  margin: 5px;
  padding: 5px;
}

.panelUsuario .modalEliminarCuenta h2 {
  font-weight: 400;
  text-align: center;
}

.panelUsuario .modalEliminarCuenta h4 {
  text-align: center;
}

.panelUsuario .modalEliminarCuenta h2 span {
  font-weight: 700;
}

.panelUsuario .modalEliminarCuenta input {
  width: 70%;
  border: none;
  border-bottom: #6d6d6d 2px solid;
  text-align: center;
  font-size: 18px;
}

.panelUsuario .modalEliminarCuenta input:focus {
  outline: none;
}

.panelUsuario .modalEliminarCuenta button {
  width: 70%;
  height: 40px;
  margin: 20px 0;
  color: white;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: rgb(194, 0, 0);
}

.panelUsuario .modalEliminarCuenta button:disabled {
  color: rgb(143, 143, 143);
  background-color: rgb(71, 0, 0);
}

.panelUsuario .modalEliminarCuenta button:disabled:hover {
  color: rgb(143, 143, 143);
  background-color: rgb(71, 0, 0);
}

.panelUsuario .modalEliminarCuenta button:hover {
  background-color: rgb(236, 0, 0);
}

.panelUsuario .modalEliminarCuenta button:active {
  background-color: rgb(194, 0, 0);
}

.panelUsuario .botonDesplegarPerfil {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  padding: 0;
  z-index: 2;
  border-radius: 100%;
  font-size: 28px;
  background-color: var(--colores-principales-cima-verde);
  color: white;
  margin: 10px;
  transition: all 0.2s;
}

.panelUsuario .botonDesplegarPerfil:hover {
  background-color: var(--colores-principales-cima-amarillo);
}

@media screen and (min-width: 1201px) {
  .panelUsuario .botonDesplegarPerfil {
    display: none;
  }
}


