.tituloMasdetalles {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icono-cerrar-modal-mas-detalles {
  /* position: absolute; */
  /* top: calc(5vh + 10px);
  right: calc(5vw + 10px); */
  width: 40px;
  height: 40px;
  color: gray;
  cursor: pointer;
}

#botonMasDetalles {
  background-color: var(--colores-principales-cima-verde);
  color: white;
  border-radius: 15px;
  font-size: 1.1rem;
  width: 125px;
  height: 45px;
  clip-path: polygon(0 0%, 100% 0%, 100% 50%, 100% 100%, 0 100%);
  transition: all 0.5s;
  margin-top: 5px;
  cursor: pointer;
}

#botonMasDetalles:hover {
  border-radius: 0;
  clip-path: polygon(0 0%, 80% 0%, 100% 50%, 80% 100%, 0 100%);
}

#botonMasDetalles:focus {
  background-color: var(--colores-principales-cima-amarillo);
}

.modal-mas-detalles {
  align-self: flex-start;
  position: sticky;
  top: 5vh;
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 3px #cecece;
  background-color: white;
  padding: 0 16px 16px 16px;
  box-sizing: border-box;
  background-image: url("../../assets/imagenes/follaje-bg.jpg");
  background-size: cover;
  overflow: auto;
}

.modal-mas-detalles h1 {
  width: 100%;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: rgb(94, 94, 94);
}

.div-caracteristicas-plano {
  display: flex;
  flex-flow: column;
  height: calc(100% - 60px);
}

.div-galeria-caracteristicas {
  flex-flow: column;
}

.div-plano,
.div-galeria-caracteristicas {
  width: 100%;
  height: stretch;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.div-galeria-mas-detalles {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  height: 250px;
  box-shadow: 0px 0px 12px 0px grey;
}

.div-carateristicas-mas-detalles {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.caracteristica-mas-detalles {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  margin: 10px;
  box-shadow: 0px 0px 12px 0px #c5c5c5;
  border-radius: 5px;
  min-height: 90px;
  background-color: white;
}

.caracteristica-mas-detalles p {
  margin: 0 0 5px 0;
  font-weight: 600;
  color: gray;
}

.caracteristica-mas-detalles span {
  margin: 0;
  font-weight: 700;
  color: gray;
  font-size: 45px;
  line-height: 38px;
}

.div-iconos-mas-detalles {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-iconos-mas-detalles img {
  max-height: 45px;
}

.div-plano img {
  max-width: 500px;
  max-height: 100%;
  box-shadow: 0px 0px 12px 0px #c5c5c5;
}

.div-video-vistas {
  width: 100%;
  height: 40vh;
  max-height: 60vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  padding: 20px;
}

.videoDeVistas {
  height: 100%;
  width: 92%;
  max-width: 940px;
  border-radius: 5px;
  max-height: 60vw;
  box-shadow: 5px 5px 14px 3px grey;
}

@media screen and (max-width: 1000px) {
  .div-caracteristicas-plano {
    flex-flow: column;
  }
  .div-plano,
  .div-galeria-caracteristicas {
    width: 100%;
    height: fit-content;
  }
  .div-caracteristicas-plano {
    overflow-y: auto;
  }
  .caracteristica-mas-detalles p {
    font-size: 10px;
  }
  .modal-mas-detalles h1 {
    font-size: 24px;
  }
  .div-plano img {
    max-height: none;
  }
}

@media screen and (max-width: 500px) {
  .div-plano img {
    max-width: 100%;
  }
}
