#botonBuscar {
    background-color: var(--colores-principales-cima-verde);
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    width: 100px;
    height: 30px;
    border-radius: 15px;
    transition: all 0.4s;
    margin-top: 5px;
    cursor: pointer;
}

#cargandoBotonBuscar {
  background-color: rgb(187, 150, 0);
  width: 50px;
  height: 50px;
  border-radius: 15px;
  transition: all 0.4s;
  margin-top: 5px;
  cursor: wait;
  display: flex;
  justify-content: center;
  align-items: center;
}

#iconoCargandoBotonBuscar {
    width: 80%;
    animation: rotation 0.7s infinite linear;
}