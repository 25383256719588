#menu {
  position: fixed;
  transition: all 0.5s;
  top: 0;
  z-index: 4;
  width: 100vw;
  transform: translateY(-100%)
}


#botonMenu {
  position: absolute;
  left: calc(50vw - 30px);
  text-align: center;
  background-color: var(--colores-principales-cima-verde);
  color: white;
  font-size: 1.1rem;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  width: 60px;
  height: 60px;
  transition: all 0.5s;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

#botonMenu:hover {
  background-color: var(--colores-principales-cima-amarillo);
}

#botonMenu p {
  text-align: center;
  font-size: 14px;
  margin: 0;
  padding: 0%;
}

.icono-menu-abierto {
  transition: all 0.5s ease;
  transform: rotate(180deg);
}

.icono-menu-cerrado {
  transition: all 0.5s ease;
}

#contenedorOpcionesMenu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: var(--colores-principales-cima-verde) solid 2px;
  box-shadow: 0px 0px 16px 10px #bfbfbf;
  padding-bottom: 10px;
}

.itemsDeMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: hsl(51, 100%, 84%);
  width: 150px;
  height: 150px;
  margin: 5px;
  transition: all 1s;
  opacity: 1;
  cursor: pointer;
  box-shadow: 0px 0px 16px 1px #e4e4e4;
  border-radius: 100%;
  text-decoration: none;
}

.itemsDeMenu:hover {
  background-color: hsl(108, 100%, 84%);
  color: var(--colores-principales-cima-amarillo);
  transition: all 0.5s;
}

.itemsDeMenu img {
  transition: all 1.4s;
  width: 50px;
  user-select: none;
  pointer-events: none;
}

.itemsDeMenu p {
  font-size: 1rem;
  font-weight: 700;
  color: var(--colores-principales-cima-verde);
  transition: all 1.5s;
  user-select: none;
  pointer-events: none;
}

.itemsDeMenu:hover p {
  color: var(--colores-principales-cima-amarillo);
  transition: all 0.5s;
}

.itemsDeMenuNoVisibles img {
  transition: all 0.5s;
  width: 1px;
}

#menuCab5 {
  top: 50px;
}

.imagenPerfilMenu {
  clip-path: circle(50%);
}

@media screen and (max-width: 560px) {
  #contenedorOpcionesMenu {
    justify-content: space-evenly;
  }

  .itemsDeMenu {
    width: 100px;
    height: 100px;
  }

  .itemsDeMenu:hover {
    width: 100px;
    height: 100px;
  }

  .itemsDeMenu img {
    width: 45px;
  }

  .itemsDeMenu p {
    font-size: 0.7rem;
  }
}
