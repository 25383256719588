.div-feedback-pago {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.div-feedback-pago h3 {
  text-align: center;
}

.botones-feedback {
  display: flex;
  max-width: 500px;
  width: 100%;
  font-size: 18px;
  justify-content: center;
  background-color: var(--colores-principales-cima-verde);
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  transition: all 0.3s;
  border: none;
  cursor: pointer;
  min-width: 130px;
}

.botones-feedback:hover {
  background-color: var(--colores-principales-cima-amarillo);
}
