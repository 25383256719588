#recuperacionDeContrasena {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#recuperacionDeContrasena form {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px #6d6d6d;
}

#recuperacionDeContrasena form input {
  width: 80%;
  border: none;
  border-bottom: 2px gray solid;
  font-size: 1rem;
  color: #6d6d6d;
  text-align: center;
  margin: 15px 0;
}

#recuperacionDeContrasena form input:focus {
  outline: none;
}

#recuperacionDeContrasena form button {
  background-color: var(--colores-principales-cima-verde);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 100px;
  height: 40px;
  border-radius: 15px;
  transition: all 0.1s;
  cursor: pointer;
}

#recuperacionDeContrasena form button:hover {
  background-color: var(--colores-principales-cima-amarillo);
}

#recuperacionDeContrasena form button:active {
  background-color: var(--colores-principales-cima-verde);
}
