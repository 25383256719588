


button {
  background-color: transparent;
  border: none;
}

button:focus {
  outline: none;
}



.divCentro {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
}

#botonVolverABuscar:hover {
  background-color: var(--colores-principales-cima-amarillo);
}

.vista {
  /* Estado inicial */
  opacity: 0;
}

.textoResaltadoVerde {
  color: var(--colores-principales-cima-verde);
  font-weight: 400;
  cursor: pointer;
}

.textoResaltadoVerde:hover {
  text-decoration: underline;
}

/* ::-webkit-scrollbar {
  display: none;
} */



@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

