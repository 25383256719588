#desglose-de-precio {
  align-self: flex-start;
  position: sticky;
  top: 5vh;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 3px #cecece;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  height: fit-content;
  max-width: 720px;
  width: 95vw;
  max-height: 90vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.div-tabla-desglose-precio {
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
}

#desglose-de-precio .div-tabla-desglose-precio table {
  width: 100%;
}

#desglose-de-precio .div-tabla-desglose-precio table td {
  border-bottom: 1px solid #e4e4e4;
  border-radius: 0px;
}

#desglose-de-precio table .td-fecha {
  font-weight: 600;
  color: dimgrey;
  text-align: center;
  width: 50%;
}

#desglose-de-precio table .td-precio {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

#desglose-de-precio table .td-precio .subtotal-descuento {
  color: dimgrey;
  text-decoration: line-through;
}

#desglose-de-precio table .td-precio .subtotal-oferta {
  color: var(--colores-secundarios-precio-oferta);
}

#desglose-de-precio .table-resumen-desglose-precio td {
  width: fit-content;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-align: right;
  padding-right: 10px;
}

#desglose-de-precio .table-resumen-desglose-precio td p {
  font-size: 18px;
  margin: 0;
}

#desglose-de-precio
  .table-resumen-desglose-precio
  .td-resumen-desglose-descripcion {
  color: dimgray;
}

#desglose-de-precio button {
  background-color: var(--colores-principales-cima-verde);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 110px;
  min-height: 40px;
  margin: 5px 0px;
  border-radius: 15px;
  transition: all 0.1s;
  cursor: pointer;
}

#desglose-de-precio button:hover {
  background-color: var(--colores-principales-cima-amarillo);
}
#desglose-de-precio button:active {
  background-color: var(--colores-principales-cima-verde);
}
