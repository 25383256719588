#cabana {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
}

#cabanaInformacion {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: rgba(255, 255, 255, 70%);
  border-radius: 5px;
  padding: 15px;
  margin: 0 5px;
  box-shadow: 2px 3px 8px black;
  color: var(--colores-principales-cima-marron);
}

.imagen-portada-vista-cabana {
  border-radius: 10px;
  width: 100%;
}

#precioVisible {
  opacity: 1;
  transition: all 1s;
}

#precioNoVisible {
  color: hsl(0, 0%, 40%);
  opacity: 0;
}

#cabana #cabanaInformacion h2{
  text-align: center;
  margin: 10px 0px;
}

#cabana #cabanaInformacion h2 .tachado {
  text-decoration: line-through;
  color: hsl(0, 0%, 40%);;
}

#cabana #cabanaInformacion h2 .precioConDescuento {
  color: var(--colores-secundarios-precio-oferta);
  font-size: 1.6rem;
}

#contenedorDeBotones {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  transition: all 1s;
}

#botonListo {
  display: none;
}

@media screen and (max-width: 1000px) {
  #cabana {
    flex-flow: column-reverse;
  }
  #cabanaInformacion {
    margin: 15px 15px 0 15px;
  }
  #cabanaOpciones {
    margin-bottom: 15px;
  }
  #botonListo {
    display: block;
    background-color: var(--colores-principales-cima-verde);
    font-size: 1.2rem;
    color: white;
    border-radius: 15px;
    padding: 10px 30px;
    margin-bottom: 10px;
    transition: all 0.5s;
    cursor: pointer;
  }
}
