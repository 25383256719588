#consultor-cupon {
  align-self: flex-start;
  position: sticky;
  top: 5vh;
  width: 300px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px #6d6d6d;
}

#consultor-cupon input {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

#consultor-cupon .div-botones-consultor-cupon {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

#consultor-cupon .div-botones-consultor-cupon button {
  background-color: var(--colores-principales-cima-verde);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 110px;
  min-height: 40px;
  margin: 5px 0px;
  border-radius: 15px;
  transition: all 0.1s;
  cursor: pointer;
}

#consultor-cupon .div-botones-consultor-cupon button:hover {
  background-color: var(--colores-principales-cima-amarillo);
}
#consultor-cupon .div-botones-consultor-cupon button:active {
  background-color: var(--colores-principales-cima-verde);
}
