#modificarDatosDeUsuario {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px #6d6d6d;
}

#modificarDatosDeUsuario label,
#modificarDatosDeUsuario input {
  width: 100%;
}

#modificarDatosDeUsuario label {
  font-size: 12px;
  font-weight: 600;
  color: rgb(133, 133, 133);
}

#modificarDatosDeUsuario input {
  border: none;
  border-bottom: #7d7d7d solid 1px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #3d3d3d;
}

#modificarDatosDeUsuario button {
  background-color: var(--colores-principales-cima-verde);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width: 200px;
  height: 40px;
  margin: 5px 0px;
  border-radius: 15px;
  transition: all 0.1s;
  cursor: pointer;
}

#modificarDatosDeUsuario button:hover {
  background-color: var(--colores-principales-cima-amarillo);
}
