#presentacionDeHuespedes {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#presentacionDeHuespedes table {
  background-color: white;
  border-spacing: 0;
  border-radius: 10px;
  margin: 0 3px;
  padding: 5px;
  box-shadow: 2px 3px 8px black;
}

#presentacionDeHuespedes h1 {
  padding: 0 5px;
  margin-bottom: 0;
}

.avisoHuespedes {
  margin: 0 0 7px 0;
  color: #a94600;
}

#presentacionDeHuespedes h5 {
  margin: 0;
}

.tipoDeHuesped {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

tr.tipoDeHuesped {
  display: flex;
  align-items: center;
  height: 40px;
}

#nombreDeTipo {
  display: flex;
  justify-content: flex-end;
}

#nombreDeTipo p {
  margin: 0;
}

.tipoDeHuesped .cantidadHuespedes {
  color: gray;
  font-weight: 700;
  font-size: 20px;
}

.tipoDeHuesped td {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 150px;
  padding: 8px 0;
}

.botonesHuespedes {
  display: flex;
  align-items: center;
  color: hsl(0, 0%, 50%);
  font-size: 30px;
  box-sizing: border-box;
  transition: all 0.08s;
  cursor: pointer;
}

.botonesHuespedes:hover {
  color: rgba(102, 55, 0);
}

.botonesHuespedes:active {
  color: var(--colores-principales-cima-amarillo);
}

@media screen and (max-width: 950px) {
  #presentacionDeHuespedes h1 {
    margin-top: 15px;
  }
}
