.resultados {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: rgba(255, 255, 255, 70%);
  border-radius: 5px;
  max-width: 210px;
  padding: 5px;
  margin: 0 5px;
  box-shadow: 2px 3px 8px black;
  /* Estado inicial */
  height: 294px;
  overflow: hidden;
  opacity: 1;
}

.resultados p {
  margin: 2px 0;
  font-weight: 700;
  color: hsl(0, 0%, 40%);
}

.resultados p .tachado {
  text-decoration: line-through;
}

.resultados p .precioConDescuento {
    color: var(--colores-secundarios-precio-oferta);
    font-size: 18px;
}

.portadasResultados {
  width: 95%;
  max-width: 165px;
  border: hsl(0, 0%, 100%) 2px solid;
  padding: 3px;
  clip-path: circle(40%);
  transition: all 1s;
}

.resultados:hover .portadasResultados {
  clip-path: circle(70%);
}

@media screen and (max-width: 750px) {
  .resultados {
    margin: 5px;
  }
}
