.panelDeReservacion {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.8);
  padding: 65px 32px 32px 32px;
  min-height: 100vh;
  box-sizing: border-box;
}

#reservacion {
  display: grid;
  grid-template-areas:
    "res cli"
    "res pag"
    "reg reg";
  gap: 16px;
  align-items: center;
  justify-content: center;
  transition: all 2s;
}

#reservacion section {
  border: rgba(0, 0, 0, 8%) 1px solid;
  border-radius: 5px;
  background-color: #fffffb;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 11%);
  height: fit-content;
  padding: 16px;
  min-height: 100%;
  max-width: 700px;
  box-sizing: border-box;
}

#reservacion h1 {
  font-size: 16px;
  background-color: #838e9014;
  color: #445d61cf;
  width: 95%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  border: #1392001f 1px solid;
  border-radius: 3px;
  font-weight: 500;
}

#botonRegresar {
  grid-area: reg;
  background-color: var(--colores-principales-cima-marron);
  display: flex;
  font-size: 18px;
  justify-content: center;
  color: white;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s;
  border: none;
  cursor: pointer;
  min-width: 130px;
  margin: auto;
}

#botonRegresar svg {
  margin-right: 10px;
}

#datosDeReservacion {
  grid-area: res;
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 500px;
}

.datosDeReservacion-bloqueTexto {
  width: 100%;
}

#datosDeReservacion h1,
#datosDeReservacion h3,
#datosDeReservacion h4,
#datosDeReservacion p,
#datosDelCliente h1,
#datosDelCliente h3,
#datosDelCliente h4,
#datosDelCliente p {
  margin: 0;
}

.contenedores-datos-reservacion {
  display: flex;
}

.contenedores-datos-reservacion label {
  font-size: 12px;
  background-color: #838e9014;
  color: #445d61cf;
  min-width: 120px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 5px;
  border: #1392001f 1px solid;
  font-weight: 500;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  text-align: right;
}

.contenedores-datos-reservacion p {
  font-size: 12px;
  width: stretch;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  border: #1392001f 1px solid;
  font-weight: 500;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

#formAnticipo {
  grid-area: ran;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  border: #1392001f 1px solid;
  border-radius: 3px;
}

#formAnticipo h4 {
  width: stretch;
  font-size: 12px;
  background-color: #838e9014;
  color: #445d61cf;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 30px;
  padding: 0px 5px;
  border-bottom: #1392001f 1px solid;
}

#formAnticipo label {
  width: stretch;
  font-size: 16px;
  font-weight: 700;
  background-color: #838e9014;
  color: #445d61cf;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  padding: 0px 5px;
  border-top: #1392001f 1px solid;
}

#datosDelCliente {
  grid-area: cli;
  display: flex;
  flex-flow: column;
  align-items: center;
}

#formDatosDelCliente {
  padding: 5px;
}

#datosDelCliente .divDatosEscritos {
  display: grid;
  grid-template-areas:
    "nom ape"
    "tel cor";
  grid-template-columns: 50%;
}

#datosDelCliente .divNombre {
  grid-area: nom;
}

#datosDelCliente .divApellido {
  grid-area: ape;
}

#datosDelCliente .divTelefono {
  grid-area: tel;
}

#datosDelCliente .divCorreo {
  grid-area: cor;
}

#datosDelCliente .divNombre,
#datosDelCliente .divApellido,
#datosDelCliente .divTelefono,
#datosDelCliente .divCorreo {
  width: 100%;
  padding: 0 2.5%;
}

#formDatosDelCliente label {
  color: #00000091;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: 10px;
  left: 8px;
  background: white;
}

#datosDelCliente input {
  padding: 8px 5px;
  font-size: 17px;
  color: #484848f0;
  border: rgb(128 128 128 / 54%) solid 1px;
  border-radius: 3px;
  box-shadow: 0px 0px 0px 0px rgb(0 178 255 / 19%);
  transition: all 0.5s;
}

#datosDelCliente input:focus {
  outline: none;
  box-shadow: 0px 0px 4px 3px rgb(0 178 255 / 19%);
}

#datosDelCliente .textoResaltadoVerde {
  text-align: center;
  font-weight: 500;
  margin-bottom: 15px;
}

input#nombreCliente,
input#apellidoCliente,
input#telefono,
input#correoElectronico {
  max-width: 500px;
  width: 90%;
}

#datosDelCliente .aceptaReglamentoSustituto {
  width: 32px;
  height: 32px;
  color: var(--colores-principales-cima-verde);
  cursor: pointer;
}

#datosDelCliente #aceptaReglamento {
  margin-left: -21px;
  opacity: 0;
  z-index: -4;
}

#datosDelCliente #labelAceptaReglamento {
  top: 0;
  left: 0;
  font-size: 14px;
  margin-left: 10px;
  max-width: 88%;
}

.cabanasARentar {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  text-align: center;
  font-size: 0.9rem;
  margin: 10px;
  box-shadow: 0px 0px 5px 1px #0000003b;
  border-radius: 8px;
}

.cabanasARentar h4 {
  background-color: #ffffc442;
  width: 100%;
  border: #0000001a 1px solid;
  color: #666666;
  font-weight: 500;
  padding: 5px 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.cabanasARentar img {
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: #0000001a 1px solid;
}

#contenedorCabanasARentar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
  width: 100%;
}

.datosDeReservacion-info-pago {
  display: grid;
  grid-template-areas:
    "des des"
    "pri seg"
    "tot tot";
}

.datosDeReservacion-info-pago .primero {
  grid-area: pri;
}

.datosDeReservacion-info-pago .segundo {
  grid-area: seg;
}

.datosDeReservacion-info-pago .descuento {
  grid-area: des;
}

.datosDeReservacion-info-pago .total {
  grid-area: tot;
}

.datosDeReservacion-info-pago .primero,
.datosDeReservacion-info-pago .segundo,
.datosDeReservacion-info-pago .descuento,
.datosDeReservacion-info-pago .total {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border: #1392001f 1px solid;
}

.datosDeReservacion-info-pago .primero label,
.datosDeReservacion-info-pago .segundo label,
.datosDeReservacion-info-pago .descuento label,
.datosDeReservacion-info-pago .total label {
  font-size: 12px;
  font-weight: 500;
  background-color: #838e9014;
  color: #445d61cf;
  width: 100%;
  text-align: center;
  border: #1392001f 1px solid;
}

.datosDeReservacion-info-pago .descuento p {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.datosDeReservacion-info-pago .descuento svg {
  margin: 0px 10px;
}

.datosDeReservacion-info-pago .descuento .precio-original {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: dimgray;
  text-decoration: line-through;
}

.datosDeReservacion-info-pago .descuento .precio-descuento {
  color: var(--colores-secundarios-precio-oferta);
}

.datosDeReservacion-info-pago .primero p,
.datosDeReservacion-info-pago .segundo p,
.datosDeReservacion-info-pago .total p {
  font-size: 30px;
  font-weight: 600;
}

.div-boton-aplicar-cupon-desglose {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.div-boton-aplicar-cupon-desglose button {
  display: flex;
  max-width: 500px;
  width: 80%;
  font-size: 16px;
  justify-content: center;
  background-color: var(--colores-principales-cima-verde);
  color: white;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s;
  border: none;
  cursor: pointer;
  min-width: 130px;
  margin-top: 10px;
}

.div-boton-aplicar-cupon-desglose .boton-quitar-cupon {
  background-color: var(--colores-principales-cima-marron);
}

.infoUsuarioPanelReservacion {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.infoUsuarioPanelReservacion p {
  color: var(--colores-principales-cima-marron);
  font-weight: 700;
  margin: 5px 0px;
}

.panelReservacionImagenPerfil {
  width: 150px;
  clip-path: circle(45%);
  cursor: pointer;
}

.panelDeReservacion .divDatosAgenciaDeViajes {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  color: var(--colores-principales-cima-marron);
  font-weight: 700;
}

.panelDeReservacion .divDatosAgenciaDeViajes img {
  width: 75px;
}

.panelDeReservacion .divDatosAgenciaDeViajes .pDatosDeSuCliente {
  font-weight: 500;
  padding-top: 5px;
  width: 100%;
}

#divLabelAceptaReglamento {
  display: flex;
  align-items: center;
}

#labelAceptaReglamento {
  font-size: 0.8rem;
}

#divBotonContinuarAPago {
  display: flex;
  max-width: 500px;
  width: 100%;
  font-size: 18px;
  justify-content: center;
  color: white;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s;
  border: none;
  cursor: pointer;
  min-width: 130px;
  margin: auto;
}

#divBotonContinuarAPago {
  background-color: var(--colores-principales-cima-verde);
}

#divBotonContinuarAPago:hover,
.div-boton-aplicar-cupon-desglose button:hover,
#botonRegresar:hover {
  background-color: var(--colores-principales-cima-amarillo);
}

#divBotonContinuarAPago:active,
.div-boton-aplicar-cupon-desglose button:active {
  background-color: var(--colores-principales-cima-marron);
}

#divBotonContinuarAPago {
  outline: none;
  transition: all 0.1s;
}

#metodoDePago {
  grid-area: pag;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.panelDeReservacion .modal-feedback-pago {
  align-self: flex-start;
  position: sticky;
  top: 5vh;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 3px #cecece;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  height: fit-content;
  max-width: 720px;
  max-height: 90vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}

@media screen and (max-width: 1000px) {
  .panelDeReservacion {
    flex-direction: column;
    height: fit-content;
    padding: 0 5px 5px 5px;
  }

  #reservacion {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    height: calc(100% - 60px);
    margin-top: 60px;
  }

  #datosDeReservacion {
    border-right: none;
    border-bottom: gray 2px dotted;
  }

  #datosDeReservacion {
    width: 100%;
  }

  #datosDelCliente {
    width: 100%;
  }

  #seccionPaypal {
    width: 0px;
    height: 0px;
    transition: all 1s;
    opacity: 0;
  }
  #reservacion section {
    min-height: fit-content;
  }
}

@media screen and (max-width: 500px) {
  .cabanasARentar {
    max-width: 130px;
  }
  .cabanasARentar h4 {
    font-size: 11px;
  }

  #formDatosDelCliente #labelAceptaReglamento {
    display: flex;
    flex-wrap: wrap;
  }

  #datosDelCliente .divDatosEscritos {
    display: grid;
    grid-template-areas:
      "nom"
      "ape"
      "tel"
      "cor";
    grid-template-columns: 100%;
  }
}

@media screen and (max-width: 380px) {
  #reservacion h1 {
    font-size: 12px;
  }
  .contenedores-datos-reservacion p {
    font-size: 9px;
  }
  .contenedores-datos-reservacion label {
    min-width: 90px;
    font-size: 9px;
  }
}

@media screen and (max-width: 290px) {
  .contenedores-datos-reservacion label {
    min-width: 70px;
  }
}
