.misReservaciones {
  width: 100%;
  height: calc(100% - 100px);
  padding: 10px;
  margin-top: 100px;
  box-sizing: border-box;
}

.misReservaciones .sinReservaciones {
  width: 100%;
  text-align: center;
}

.misReservaciones .tabla-dinamica {
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.misReservaciones .contenedor-general-tabla-dinamica{
  height: calc(100vh - 200px);
}

