#perfil-fixed-div-invisible {
  position: relative;
  height: 100vh;
  align-self: flex-start;
}

/* .barraLateralIzquierda {
  transform: translateY(-100%);
} */

.perfil {
  position: sticky;
  top: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  background: white;
  box-sizing: border-box;
  height: 100%;
  padding: 60px 16px;
  /* opacity: 0; */
  box-shadow: 0px -1px 20px 0px #a4a4a4;
}

.perfil .datosDeUsuario {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.perfil .opcionesPerfil {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.perfil p {
  font-weight: 700;
  margin: 5px 0px;
  color: rgb(82, 82, 82);
  text-align: center;
}

.divImagenPerfil {
  max-width: 150px;
  clip-path: circle(42%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.perfil .imagenDePerfil {
  max-width: 150px;
  clip-path: circle(42%);
  cursor: pointer;
  transition: all 0.5s;
}

.perfil .imagenDePerfil:hover {
  max-width: 155px;
}

.perfil .opcionesPerfil button {
  width: 100%;
  height: 40px;
  margin: 5px 0;
  color: white;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s;
}

.perfil .opcionesPerfil .botonActualizar {
  background-color: var(--colores-principales-cima-verde);
}

.perfil .opcionesPerfil .botonCerrar {
  background-color: var(--colores-principales-cima-amarillo);
}

.perfil .opcionesPerfil .botonBorrar {
  background-color: var(--colores-principales-cima-cafe);
}

.perfil .opcionesPerfil .botonSeguimiento {
  background-color: rgb(66, 66, 66);
}

.perfil .opcionesPerfil button:hover {
  background-color: var(--colores-principales-cima-marron);
}

.perfil .opcionesPerfil button:active {
  background-color: var(--colores-principales-cima-verde);
}

@media screen and (max-width: 1200px) {
  #perfil-fixed-div-invisible {
    position: fixed;
    z-index: 2;
  }

  .panelUsuario .barraLateralIzquierda {
    left: -100%;
  }
}
